import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import '../styles/App.css';

// const styles = {
//     link: {
//         textDecoration: 'none',
//         color: '#222222',
//     },
//     button: {
//         marginRight: 5,
//     },
// };

export const Header = () => {
    return (
        <>
            <Box sx={{ flexGrow: 1 }}>
                <AppBar position='static' color='secondary'>
                    <Toolbar>
                        <Typography
                            variant='h6'
                            component='div'
                            sx={{ flexGrow: 1 }}
                        >
                            Max Cape Tracker - Old School Runescape
                        </Typography>
                        {/* <Link style={styles.link} to='/'>
                            <Button
                                style={styles.button}
                                color='inherit'
                                variant='outlined'
                            >
                                Donate
                            </Button>
                        </Link> */}
                    </Toolbar>
                </AppBar>
            </Box>
        </>
    );
};
