import { ThemeProvider, createTheme } from '@mui/material';
import { blue } from '@mui/material/colors';
import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import { Layout } from './layout/Layout';
import { Header } from './layout/Header';
import NotFound from './pages/NotFound';

export const appTheme = createTheme({
    palette: {
        primary: blue,
        secondary: blue,
        background: {
            default: '#222222',
        },
    },
});

const App = () => (
    <ThemeProvider theme={appTheme}>
        <Layout header={<Header />}>
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path='*' element={<NotFound />} />
            </Routes>
        </Layout>
    </ThemeProvider>
);

export default App;
