import { useState, useEffect } from 'react';
import {
    Button,
    TextField,
    Box,
    CircularProgress,
    Typography,
    Grid,
    LinearProgress,
} from '@mui/material';
import { config } from '../configuration';
import axios from 'axios';
import '../styles/App.css';

const Home = () => {
    const [username, setUsername] = useState('');
    const [skillData, setSkillData] = useState<ISkill[] | null>(null);
    const [maxedPercent, setMaxedPercent] = useState(0);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('')
    const [width, setWidth] = useState<number>(window.innerWidth);

    const isMobile = width <= 768;

    const styles = {
        form: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 20,
        },
        searchButton: {
            marginLeft: 10,
            height: 50,
        },
        skill: {
            display: 'flex',
            alignItems: 'center',
        },
        loadingCircle: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: isMobile ? 200 : 150,
        },
        maxedCircle: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 20,
        },
        skillGrid: {
            marginLeft: 10,
            marginRight: 40,
        },
        progressLabel: {
            position: 'absolute' as 'absolute',
            height: '100%',
            zIndex: 1,
            maxHeight: 20,
            display: 'flex',
            alignItems: 'center',
            justifyItems: 'center',
        },
    };

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, []);

    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
    }

    const handleError = (message: string) => {
        setErrorMessage(message)
        setError(true);
    }

    const clearError = () => {
        setError(false);
        setErrorMessage('')
    }

    const performLookup = async () => {
        try {
            setMaxedPercent(0);
            setSkillData(null);
            setLoading(true);
            const response = await axios.post(`${config.API_BASE_URL}/skills`, {
                username: username.toLowerCase(),
            });
            
            if (response.data.status === 200) {
                const { skills } = response.data.body;
                skills.shift();
                setSkillData(skills);
                clearError();
            } else if (response.data.status === 404) {
                handleError('Username not found on the OSRS Hiscores.')
            } else {
                handleError('Max-cape.com has not been updated to support the latest OSRS hiscore changes.')
            }
        } catch (err) {
            handleError('Max-cape.com has not been updated to support the latest OSRS hiscore changes.')
        } finally {
            setLoading(false);
        }
    };

    const numberWithCommas = (x: number) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    };

    interface ISkill {
        id: number,
        name: string,
        rank: number,
        level: number,
        xp: number
    };

    interface ISkillSectionProps {
        skillData: ISkill[]
    }

    const SkillSection = ({ skillData }: ISkillSectionProps) => {
        console.log(skillData)
        var totalXp = 0;

        var steps: any = [];
        skillData.forEach((skill: ISkill, index: number) => {
            const percentage = Math.min(
                100,
                (skill.xp / 13034431) * 100
            );

            totalXp +=
                skill.xp >= 13034431
                    ? 13034431
                    : skillData[index].xp;

            steps.push(
                <>
                    <Grid
                        item
                        xs={isMobile ? 4 : 1}
                        style={{ borderBottom: '1px solid black' }}
                    >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Box style={{ width: 25, height: 25 }}>
                                <img
                                    src={require(`../assets/icons/${skillData[index].name.toLowerCase()}.png`)}
                                    alt=''
                                />
                            </Box>
                            <p
                                style={{ marginLeft: 5 }}
                            >{`${skill.level}`}</p>
                        </div>
                    </Grid>
                    <Grid
                        item
                        xs={isMobile ? 4 : 1}
                        style={{ borderBottom: '1px solid black' }}
                    >
                        <p>{`${
                            skillData[index].xp === -1
                                ? '-'
                                : numberWithCommas(skillData[index].xp)
                        }`}</p>
                    </Grid>
                    <Grid
                        item
                        xs={isMobile ? 4 : 10}
                        style={{
                            paddingRight: 20,
                            borderBottom: '1px solid black',
                        }}
                    >
                        {isMobile ? (
                            `${
                                percentage !== 100
                                    ? Math.abs(percentage).toFixed(2)
                                    : percentage
                            }%`
                        ) : (
                            <Grid container spacing={0}>
                                <Grid
                                    item
                                    xs={0.6}
                                    style={{
                                        backgroundColor: '#21509c',
                                        textAlign: 'center',
                                        borderTopLeftRadius: 20,
                                        borderBottomLeftRadius: 20,
                                        color: '#FFF',
                                    }}
                                >
                                    {`${
                                        percentage !== 100
                                            ? Math.abs(percentage).toFixed(2)
                                            : percentage
                                    }%`}
                                </Grid>
                                <Grid item xs={11.4}>
                                    <LinearProgress
                                        value={percentage}
                                        variant='determinate'
                                        color='info'
                                        style={{
                                            height: 20,
                                            borderTopRightRadius: 20,
                                            borderBottomRightRadius: 20,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </>
            );
        });

        setMaxedPercent(getMaxedPercent(totalXp));

        return (
            <Grid container spacing={1} style={styles.skillGrid}>
                {steps.length !== 0 && (
                    <>
                        <Grid item xs={isMobile ? 4 : 1}>
                            <Typography>
                                <b>Skill</b>
                            </Typography>
                        </Grid>
                        <Grid item xs={isMobile ? 4 : 1}>
                            <Typography>
                                <b>XP</b>
                            </Typography>
                        </Grid>
                        <Grid item xs={isMobile ? 4 : 10}>
                            <Typography>
                                <b>Progress</b>
                            </Typography>
                        </Grid>
                    </>
                )}
                {steps}
            </Grid>
        );
    };

    const LoadingCircle = () => {
        return (
            <Box style={styles.loadingCircle}>
                <CircularProgress size={120} thickness={2} />
            </Box>
        );
    };

    const CircularProgressWithLabel = (props: any) => {
        return (
            <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                <CircularProgress variant='determinate' {...props} />
                <Box
                    sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Typography
                        variant='caption'
                        component='div'
                        color='text.secondary'
                    >
                        {`${Math.round(props.value)}% maxed`}
                    </Typography>
                </Box>
            </Box>
        );
    };

    const getMaxedPercent = (currentXp: number) => {
        const totalMaxedXp = 13034431 * 23;

        return (currentXp / totalMaxedXp) * 100;
    };

    const MaxedPercentage = () => {
        return (
            <Box style={styles.maxedCircle}>
                <CircularProgressWithLabel
                    variant='determinate'
                    size={120}
                    value={maxedPercent}
                />
            </Box>
        );
    };

    const MainContent = () => {
        if (loading === true) {
            return <LoadingCircle />;
        }
        
        if (skillData !== null) {
            return (
                <>
                    <MaxedPercentage />
                    <SkillSection skillData={skillData} />
                </>
            );
        } else if (error) {
            return (
                <div
                    style={{
                        padding: 10,
                        border: '1px solid red',
                        textAlign: 'center',
                        backgroundColor: '#ffcccc',
                        margin: '0 auto',
                        width: '60%',
                        marginTop: 50,
                    }}
                >
                    <p>{errorMessage}</p>
                </div>
            );
        } else {
            return <></>;
        }
    };

    return (
        <>
            <Box style={styles.form}>
                <TextField
                    variant='outlined'
                    placeholder='Username'
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    onKeyUp={(e) => {
                        e.key === 'Enter' && performLookup();
                    }}
                ></TextField>
                <Button
                    style={styles.searchButton}
                    variant='outlined'
                    onClick={() => performLookup()}
                    value={username}
                >
                    Search
                </Button>
            </Box>

            <MainContent />
        </>
    );
};

export default Home;
